import React from 'react'
import axios from 'axios'
import { graphql, Link } from 'gatsby'
import filter from 'lodash/filter'
import { pageLinks, useInfoPagesData } from '../hooks/infoPages'
import Seo from '../components/seo'
import SectionTitle from '../components/sectionTitle'
import FreeReturnsLine from '../components/freeReturnsLine'
import SectionDefault from '../components/sectionDefault'
import Logo from '../components/logo'
import NavItem from '../components/navItem'
import Icon from '../components/icon'
import amex from '../images/payment/amex.svg'
import dinersClub from '../images/payment/diners-club.svg'
import discover from '../images/payment/discover.svg'
// import maestro from '../images/payment/maestro-pay.svg'
import mastercard from '../images/payment/mastercard-pay.svg'
import paypal from '../images/payment/paypal.svg'
import visa from '../images/payment/visa-pay.svg'
import styles from '../styles/styles'
import { templatePath } from '../helpers'
import find from 'lodash/find'

const PaymentCardLogo = ({ h = 'h-5', img, alt }) => (
  <img className={`${h} mr-3 mb-3 last:mr-0`} src={img} alt={alt} />
)

const ErrorMessage = ({ errorMessage }) => (
  <div className="text-center text-red-600 mb-6">{errorMessage}</div>
)

const VersionHeader = ({ version, vatRate, vatCountry, isVatFetched, hasBack }) => {
  const vatAmount = (version.price * vatRate) / 100
  const fullPrice = version.price + vatAmount
  const fullPriceBase = Math.floor(fullPrice)
  const fullPriceDecPoints = Math.round((fullPrice - fullPriceBase) * 100)
  const fullPriceDecPointsString = fullPriceDecPoints > 0 ? '.' + fullPriceDecPoints.toString() : ''

  return (
    <div className={`flex-shrink mb-6 flex justify-between`}>
      <div className={`flex justify-start items-center`}>
        {hasBack && (
          <button type={`button`} className={`mr-3`} onClick={() => (window.location.hash = '')}>
            <Icon icon={`mdiChevronLeft`} size={36} wh={`h-12`} />
          </button>
        )}
        <div>
          <h4 className="text-xl font-semibold">{version.title}</h4>
          <p className="text-sm mb-1">{version.subtitle}</p>
        </div>
      </div>
      <div className={`text-right`}>
        {/*<h4 className="text-3xl">${ fullPrice }</h4>*/}
        {isVatFetched && (
          <h4 className="text-3xl">
            ${fullPriceBase}
            <sup className={`text-xl`}>{fullPriceDecPointsString}</sup>
          </h4>
        )}
        {vatRate > 0 && (
          <p className={`text-xs`}>
            Includes{' '}
            <abbr
              className={`cursor-help`}
              title={`VAT ${vatRate}% for ${vatCountry}. VAT is refunded automatically if you provide VAT ID`}
            >
              VAT {vatRate}%
            </abbr>
          </p>
        )}
      </div>
    </div>
  )
}
class PrePayForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      method: 'card',
      email: '',
      isLoading: false,
      errorMessage: null,
    }

    this.paypalForm = React.createRef()

    this.handleEmailInput = this.handleEmailInput.bind(this)
    this.handleMethodChange = this.handleMethodChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleEmailInput(event) {
    this.setState({
      email: event.target.value,
    })
  }

  handleMethodChange(event) {
    this.setState({
      method: event.target.value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    this.setState({
      isLoading: true,
      errorMessage: null,
    })

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }

    axios
      .post('https://jbapp.vikdiesel.workers.dev/pre-order', {
        email: this.state.email,
        slug: this.props.slug,
        version: this.props.version.title,
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })

        const buyUrlWithParams = `${this.props.buyUrl}?variant=${this.props.version.title}&wanted=true`

        window.location.href = validateEmail(this.state.email)
          ? `${buyUrlWithParams}&email=${this.state.email}`
          : buyUrlWithParams
      })
    // .then(() => {
    //   this.setState({
    //     isLoading: false
    //   })

    //   // if (this.state.method === 'paypal') {
    //   //   this.paypalForm.current.submit()
    //   // } else {
    //   //   window.location.href = `${buyUrlWithParams}&email=${this.state.email}`
    //   // }

    //   window.location.href = `${buyUrlWithParams}&email=${this.state.email}`
    // })
    // .catch(err => {
    //   console.log('jberr', err)

    //   // if (validateEmail(this.state.email))

    //   const errorMessage = err.response && err.response.data && err.response.data.errorMessage
    //     ? err.response.data.errorMessage
    //     : err.message

    //   this.setState({
    //     isLoading: false,
    //     errorMessage
    //   })
    // })
  }

  render() {
    const formClassBase = [
      'max-w-2xl mx-auto p-6 rounded-xl bg-white',
      this.state.errorMessage
        ? 'border-red-700 border-2 animate-shake'
        : 'border-gray-900 border-4',
    ]

    const helpEmail = this.state.errorMessage ?? 'Please enter your e-mail address'

    const inputClassBase = [styles.input, 'w-full']

    const inputEmailClassBase = [this.state.errorMessage ? 'border-red-700' : '', ...inputClassBase]

    const labelClassName = 'block font-semibold mb-1'

    const helpClassName = 'text-xs ml-1 mt-1'

    const textErrorClassName = 'text-red-600'

    const buttonLabel = this.state.method === 'paypal' ? 'Proceed to PayPal' : 'Proceed to Gumroad'

    return (
      <>
        {this.state.errorMessage && <ErrorMessage errorMessage={this.state.errorMessage} />}

        <form className={formClassBase.join(' ')} onSubmit={this.handleSubmit}>
          <VersionHeader
            version={this.props.version}
            vatRate={this.props.vatRate}
            vatCountry={this.props.vatCountry}
            isVatFetched={this.props.isVatFetched}
            hasBack={true}
          />

          <div className="mb-12">
            <label
              htmlFor="prePayEmail"
              className={`${labelClassName} ${this.state.errorMessage ? textErrorClassName : ''}`}
            >
              Your email
            </label>
            <input
              id="prePayEmail"
              className={inputEmailClassBase.join(' ')}
              onChange={this.handleEmailInput}
              type="email"
              placeholder="john.doe@example.com"
              autoComplete="email"
              required={true}
            />
            <p
              className={`${helpClassName} ${
                this.state.errorMessage ? textErrorClassName : 'text-gray-500'
              }`}
            >
              {helpEmail}
            </p>
          </div>

          {/* <div className="mb-12">
            <label className={`${labelClassName} ${this.state.errorMessage ? textErrorClassName : ''}`}>
              Payment method
            </label>
            <div className={`space-y-1`}>
              <div>
                <label className={`inline-flex items-center cursor-pointer`}>
                  <input type={`radio`} value={`paypal`} checked={this.state.method === 'paypal'} onChange={this.handleMethodChange}/>
                  <span className={`ml-1.5`}>PayPal</span>
                </label>
              </div>
              <div>
                <label className={`inline-flex items-center cursor-pointer`}>
                  <input type={`radio`} value={`card`} checked={this.state.method === 'card'} onChange={this.handleMethodChange}/>
                  <span className={`ml-1.5`}>Card / Apple Pay / Google Pay</span>
                </label>
              </div>
            </div>
            <p className={`${helpClassName} ${errorEmail ? textErrorClassName : 'text-gray-500'}`}>
             { helpEmail }
            </p>
          </div> */}

          <div className="flex justify-center">
            <button
              type="submit"
              className={`${styles.buttonDefaultSpaced} ${
                this.state.isLoading ? 'opacity-50' : ''
              }`}
            >
              {buttonLabel}
            </button>
          </div>

          <div className="mt-12 flex flex-wrap items-center justify-center filter grayscale opacity-70">
            <PaymentCardLogo img={visa} alt="Visa" h="h-4" />
            <PaymentCardLogo img={amex} alt="American Express" />
            <PaymentCardLogo img={dinersClub} alt="Diners Club" />
            <PaymentCardLogo img={mastercard} alt="MasterCard" />
            {/*<PaymentCardLogo img={maestro} alt="Maestro" />*/}
            <PaymentCardLogo img={discover} alt="Discover" h="h-4" />
            <PaymentCardLogo img={paypal} alt="PayPal" h="h-4" />
          </div>

          <p className="text-gray-500 text-xs text-center">
            Problems with payment? Drop an e-mail to{' '}
            <a href="mailto:viktor@justboil.me" className="normal">
              viktor@justboil.me
            </a>
          </p>
          <p className="text-gray-500 text-xs text-center">
            Payments are processed by Gumroad, Inc.
            <br />
            548 Market St, San Francisco, CA 94104-5401, USA
          </p>
        </form>
        <form ref={this.paypalForm} action="https://www.paypal.com/cgi-bin/webscr" method="post">
          {/*<form ref={this.paypalForm} action="https://app.justboil.me" method="post">*/}
          <input
            type="hidden"
            name="image_url"
            value="https://static.justboil.me/assets/logo-150-50.png"
          />
          <input type="hidden" name="business" value="" />
          <input type="hidden" name="cmd" value="_xclick" />
          <input
            type="hidden"
            name="item_name"
            value={`${this.props.title} / ${this.props.version.title}`}
          />
          <input
            type="hidden"
            name="item_number"
            value={`${this.props.slug}--${this.props.version.title}`}
          />
          <input type="hidden" name="amount" value={this.props.version.price} />
          <input type="hidden" name="currency_code" value="USD" />
          <input type="hidden" name="no_shipping" value="1" />
          <input type="hidden" name="return" value="https://app.justboil.me/api/paypal/success" />
          <input type="hidden" name="rm" value="2" />
          <input
            type="hidden"
            name="cancel_return"
            value="https://app.justboil.me/api/paypal/failure"
          />
        </form>
      </>
    )
  }
}

class PricingTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      version: null,
    }

    this.handleVersionChange = this.handleVersionChange.bind(this)
    this.handleHashChange = this.handleHashChange.bind(this)
  }

  componentDidMount() {
    window.addEventListener('hashchange', this.handleHashChange)

    this.handleHashChange()
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.handleHashChange)
  }

  handleHashChange(e = null) {
    if (e) {
      e.preventDefault()
    }

    this.setState({
      version: window.location.hash === '' ? null : window.location.hash.slice(1),
    })
  }

  handleVersionChange(version) {
    window.location.hash = version.title
  }

  versions = [
    {
      title: 'Standard',
      subtitle: 'Single app',
      options: [
        <span>
          <b>Single</b> app
        </span>,
        <span>
          <b>24 hours</b> to return (if not satisfied)
        </span>,
        <span>
          <b>6 months</b> of free updates
        </span>,
        <span>
          Use in a <b>free end product</b>
        </span>,
        <span>
          <b>Personal</b> use or for a <b>client</b>
        </span>,
      ],
      price: 49,
    },
    {
      title: 'Multisite',
      subtitle: 'Unlimited apps',
      options: [
        <span>
          <b>Unlimited</b> apps
        </span>,
        <span>
          <b>24 hours</b> to return (if not satisfied)
        </span>,
        <span>
          <b>12 months</b> of free updates
        </span>,
        <span>
          Use in a <b>free end product</b>
        </span>,
        <span>
          <b>Personal</b> use or for a <b>client</b>
        </span>,
      ],
      price: 129,
    },
    {
      title: 'Extended',
      subtitle: 'Unlimited apps + Paying users',
      options: [
        <span>
          <b>Unlimited</b> apps
        </span>,
        <span>
          <b>14 days</b> to return (if not satisfied)
        </span>,
        <span>
          <b>12 months</b> of free updates
        </span>,
        <span>
          Use in <b>SaaS with paying users</b>
        </span>,
        <span>
          <b>Priority</b> support
        </span>,
        <span>
          <b>Personal</b> use or for a <b>client</b>
        </span>,
      ],
      price: 199,
    },
  ]

  render() {
    if (this.state.version !== null) {
      return (
        <PrePayForm
          slug={this.props.slug}
          title={this.props.title}
          buyUrl={this.props.buyUrl}
          version={find(this.versions, ['title', this.state.version])}
          vatRate={this.props.vatRate}
          vatCountry={this.props.vatCountry}
          isVatFetched={this.props.isVatFetched}
        />
      )
    }

    return (
      <div className={`grid grid-cols-1 xl:grid-cols-3 gap-6`}>
        {this.versions.map((version) => (
          <div
            key={version.title}
            className={`flex flex-col justify-between border-4 bg-white border-gray-900 p-6 rounded-3xl`}
          >
            <VersionHeader
              version={version}
              vatRate={this.props.vatRate}
              vatCountry={this.props.vatCountry}
              isVatFetched={this.props.isVatFetched}
            />
            <div className={`flex-grow space-y-3 mb-6`}>
              {version.options.map((option, index) => (
                <div key={index}>{option}</div>
              ))}
            </div>
            <div>
              <div className="flex items-center justify-center">
                <button
                  type={`button`}
                  className={styles.buttonDefaultLarge}
                  onClick={() => this.handleVersionChange(version)}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

class BuyPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      vatRate: 0,
      vatCountry: null,
      isVatFetched: false,
    }

    axios
      .get('https://eu-vat.vikdiesel.workers.dev')
      .then((r) => {
        this.setState({
          vatRate: r.data.vat ?? 0,
          vatCountry: r.data.country,
          isVatFetched: true,
        })
      })
      .catch((err) => {
        this.setState({
          isVatFetched: true,
        })
      })
  }

  render() {
    return (
      <>
        <section className="max-w-4xl mx-auto my-6 px-6">
          <Link to={templatePath(this.props.item)}>&larr; Back to template page</Link>
        </section>
        <Seo title={`Buy ${this.props.item.title}`} />
        <SectionTitle
          title={`Buy ${this.props.item.title}`}
          subtitle={this.props.item.subtitle}
          tags={this.props.item.tags}
          style={'default'}
        />
        {/*<SectionDefault>*/}
        {/*  <TemplateCard*/}
        {/*    image={this.props.item.screenshot}*/}
        {/*    alt={this.props.item.longTitle}*/}
        {/*    icons={this.props.item.icons}*/}
        {/*    className="mx-auto max-w-md mb-24"*/}
        {/*  />*/}
        {/*</SectionDefault>*/}
        <SectionDefault>
          <PricingTable
            slug={this.props.item.slug}
            title={this.props.item.title}
            buyUrl={this.props.item.buyUrl}
            vatRate={this.state.vatRate}
            vatCountry={this.state.vatCountry}
            isVatFetched={this.state.isVatFetched}
          />
        </SectionDefault>
        {/*<SectionDefault>*/}
        {/*  <PrePayForm slug={this.props.item.slug} buyUrl={this.props.item.buyUrl} />*/}
        {/*</SectionDefault>*/}
        <section className="max-w-6xl mx-auto text-center mt-12 mb-24 px-12 sm:px-6">
          <div className="flex flex-wrap justify-center -mb-3">
            {this.props.pageLinksData.map((pageLink) => (
              <NavItem key={pageLink.to ?? pageLink.href} to={pageLink.to} href={pageLink.href}>
                {pageLink.shortLabel}
              </NavItem>
            ))}
          </div>

          <div className={`mt-12`}>
            {this.state.isVatFetched && this.state.vatRate > 0 && (
              <>
                <div className={`text-center text-sm`}>
                  <b>VAT {this.state.vatRate}%</b> for <b>{this.state.vatCountry}</b> will apply
                </div>
                <div className={`text-center text-sm`}>
                  <b>VAT is refunded</b> if you provide <b>VAT ID</b>
                </div>
              </>
            )}
            <div className={`text-center text-sm`}>
              <FreeReturnsLine />
            </div>
          </div>

          {/*<div className="mt-12 flex flex-wrap items-center justify-center filter grayscale opacity-70">*/}
          {/*  <PaymentCardLogo img={visa} alt="Visa" h="h-4" />*/}
          {/*  <PaymentCardLogo img={amex} alt="American Express" />*/}
          {/*  <PaymentCardLogo img={dinersClub} alt="Diners Club" />*/}
          {/*  <PaymentCardLogo img={mastercard} alt="MasterCard" />*/}
          {/*  /!*<PaymentCardLogo img={maestro} alt="Maestro" />*!/*/}
          {/*  <PaymentCardLogo img={discover} alt="Discover" h="h-4" />*/}
          {/*  <PaymentCardLogo img={paypal} alt="PayPal" h="h-4" />*/}
          {/*</div>*/}

          {/*<p className="text-gray-500 text-xs">*/}
          {/*  Problems with payment? Drop an e-mail to <a href="mailto:viktor@justboil.me" className="normal">viktor@justboil.me</a>*/}
          {/*</p>*/}
        </section>
        <section className="flex justify-center my-24 px-6">
          <Link to="/">
            <Logo />
          </Link>
        </section>
      </>
    )
  }
}

const Buy = ({
  data: {
    mdx: { frontmatter: item },
  },
}) => (
  <BuyPage
    item={item}
    pageLinksData={filter(pageLinks(useInfoPagesData()), (pageLink) => pageLink.listedOnBuyPage)}
  />
)

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        filter
        slug
        title
        subtitle
        longTitle
        tags
        icons
        buyUrl
      }
    }
  }
`

export default Buy
